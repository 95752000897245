@import '@one/web-components/dist/owc/owc.css';
@import '@one/icons/dist/one-icons-outlined.css';
@import '@one/icons/dist/one-icons-filled.css';
@import '@one/design-tokens/css/regular/navify/light/variables.css';
@import '@one/roche-font/roche-font.css';

html,
body {
  margin: 0;
  font-family: 'Roche Sans', Roboto, 'Helvetica Neue', sans-serif !important;
}

// common styles that can be reused across the application
.font-bold {
  font-weight: bold;
}

.font-warn {
  color: var(--one-color-red-400) !important;
}

.string-break {
  word-break: break-word;
}

.background-light-gray {
  background-color: var(--one-color-gray-50) !important;
}
